import "./infrastructure/browser"
import "./infrastructure/tenant"
import "./plugins/monaco"
import * as React from 'react';
import { layout } from './layout';
import { createBlazorApi } from 'piral-blazor'
import { errors } from './components/Errors';
import { Route, Switch } from 'react-router';
import { createRoot } from 'react-dom/client';
import { SwitchToLayoutDefault, SwitchToLayoutEmpty, SwitchToLayoutNoSidebar } from './layoutSwitchers';
import { Piral, createInstance, SetComponent } from 'piral-core';
import { loadPilets, Pilet, standardStrategy } from 'piral-base';
import { LoadingSpinner } from './components/LoadingSpinner';
import { redirectToFirstApp, requestPilets } from './infrastructure/requestPilets';
import { getCurrentAccount, msalInstance } from './infrastructure/msal';
import { getInitialLanguage } from './infrastructure/localization';
import { env } from "./infrastructure/environment";

//enable debugging
if (env.DEBUG_PILET === 'true') {
  sessionStorage.setItem('dbg:load-pilets', 'on')
}

async function main() {
  //initialize the piral instance
  const piralInstance = createInstance({
    state: {
      components: layout,
      errorComponents: errors,
    },
    plugins: [
      createBlazorApi({
        lazy: false,
        logLevel: 3,
        initialLanguage: getInitialLanguage(),
        //onLanguageChange: callback => {}
      }),
    ],
    requestPilets() {
      return requestPilets()
    },
    async: (options, pilets) => {
      standardStrategy(options, pilets);
      const totalPilets = pilets.length;
      let loadedPilets: string[] = [];
      return new Promise(resolve => {
        window.addEventListener('loaded-blazor-pilet', async (ev: CustomEvent) => {
          loadedPilets.push(ev.detail.name);
          if (ev.detail.name === '@casablanca/cloud-ui') {
            // The layout has been loaded completely. We can now hide the spinner and show the app.
            resolve();
          }
          if (window.location.pathname === "/" && loadedPilets.length === totalPilets) {
            await redirectToFirstApp(loadedPilets);
          }
        }); 
      });
    },
  });

  piralInstance.on('currentTenantChanged', async(newTenantId: string) => {
    await loadPilets(
      () => requestPilets(),
      async (pilet: Pilet): Promise<Pilet> => {
        return new Promise<Pilet>((resolve, reject) => {
          try {
            // Here, implement your loading logic for loading the pilet if needed
            resolve(pilet);
          } catch (error) {
            reject(error);
          }
        });
      }
    );
  });

  //initialize msal authentication
  await msalInstance.initialize();
  await msalInstance.handleRedirectPromise();
  await getCurrentAccount();

  //render piral right after authenticating  
  const root = createRoot(document.querySelector('#app'));
  root.render(<Piral instance={piralInstance}>
    <SetComponent name="LoadingIndicator" component={LoadingSpinner} />
    <Switch> 
      <Route path="/:tenantId" exact component={SwitchToLayoutNoSidebar} />
      <Route path="/users/current" exact component={SwitchToLayoutNoSidebar} />
      <Route path="/iam/resources" exact component={SwitchToLayoutNoSidebar} />
      <Route path="/iam/organizations" exact component={SwitchToLayoutNoSidebar} />
      <Route path="/iam/invitations" exact component={SwitchToLayoutNoSidebar} />
      <Route path="/iam/user" exact component={SwitchToLayoutNoSidebar} />
      <Route path="/authentication/*" component={SwitchToLayoutEmpty} />
      <Route path="/app/*" component={SwitchToLayoutNoSidebar} />
      <Route path="/" exact component={SwitchToLayoutNoSidebar} />
      <Route path="/" component={SwitchToLayoutDefault} />
    </Switch>
  </Piral>);
}

main().catch((error) => {
  console.error("Failed to start the application", error);
});