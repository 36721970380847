import { ExtensionSlot, LayoutProps } from 'piral-core';
import * as React from 'react';

export const LayoutNoSidebar: React.FC<LayoutProps> = (props) => {
  return <div className="layout-wrapper">
    <div className="layout layout--no-sidebar">
      <ExtensionSlot name="Cloud.Topbar" />
      <div className="layout_content">
        <main>{props.children}</main>
      </div>
    </div> 
  </div>
};
