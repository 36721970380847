window.addEventListener("contextmenu", event => {
  if(!event.ctrlKey){
    event.preventDefault();
  }
});

// hide error since its not a bug according to piral (https://github.com/smapiot/Piral.Blazor/issues/148)
window.MutationEvent = window.MutationEvent || function() {};

const originalError = console.error;
console.error = function() {
    const errorMessage = Array.from(arguments).join(' ');
    
    // Check if the error message contains any of the patterns we want to suppress
    if (errorMessage.includes('<circle> attribute r')  //hide error created by mudblazor since its not that important and i cant fix it (https://github.com/MudBlazor/MudBlazor/issues/8780)
      || errorMessage.includes('DOMNodeInsertedIntoDocument') // hide error since its not a bug according to piral (https://github.com/smapiot/Piral.Blazor/issues/148)
    ) {
        return; // Suppress the error
    }
    
    // Call original console.error for other errors
    originalError.apply(console, arguments);
};
