import { Link } from "react-router-dom";
import * as React from 'react';
import { ErrorComponentsState } from "piral-core";

export const errors: Partial<ErrorComponentsState> = {
  not_found: () => (
    <div>
      <p className="error">
        Could not find the requested page. Are you sure it exists?
      </p>
      <p>
        Go back <Link to="/app">home</Link>.
      </p>
    </div>
  ),
};
 