import * as React from "react";
import { LayoutDefault } from "./layouts/LayoutDefault";
import { LayoutEmpty } from "./layouts/LayoutEmpty";
import { LayoutNoSidebar } from "./layouts/LayoutNoSidebar";
import { StateContext } from "piral-core";

export const SwitchToLayoutDefault = () => {
  const context = React.useContext(StateContext);
  React.useEffect(() => {
    context.setComponent("Layout", LayoutDefault);
    return () => context.setComponent("Layout", LayoutDefault);
  }, []);
  return null;
};

export const SwitchToLayoutEmpty = () => {
  const context = React.useContext(StateContext);
  React.useEffect(() => {
    context.setComponent("Layout", LayoutEmpty);
    return () => context.setComponent("Layout", LayoutDefault);
  }, []);
  return null;
};

export const SwitchToLayoutNoSidebar = () => {
  const context = React.useContext(StateContext);
  React.useEffect(() => {
    context.setComponent("Layout", LayoutNoSidebar);
    return () => context.setComponent("Layout", LayoutDefault);
  }, []);
  return null;
};
