import { acquireToken } from "../msal";

const authenticatedFetch = async function(input: RequestInfo, init?: RequestInit): Promise<Response> {
    // Acquire the token
    const token = await acquireToken();

    // Add the token to the request headers
    const authHeaders = new Headers(init?.headers || {});
    authHeaders.set('Authorization', `Bearer ${token}`);

    // Execute the request with the modified headers
    return fetch(input, {
        ...init,
        headers: authHeaders,
    });
}

export default authenticatedFetch;