
import * as monaco from 'monaco-editor';

declare global {
  interface Window { monaco: any; }
}

window.MonacoEnvironment = {
  getWorker: (workerId, label) => {
    if (label === 'json') {
      return new Worker(new URL('monaco-editor/esm/vs/language/json/json.worker?worker', import.meta.url));
    }
    if (label === 'typescript' || label === 'javascript') {
      return new Worker(new URL('monaco-editor/esm/vs/language/typescript/ts.worker?worker', import.meta.url));
    }
    if (label === 'css') {
      return new Worker(new URL('monaco-editor/esm/vs/language/css/css.worker?worker', import.meta.url));
    }
    if (label === 'html') {
      return new Worker(new URL('monaco-editor/esm/vs/language/html/html.worker?worker', import.meta.url));
    }
    return new Worker(new URL('monaco-editor/esm/vs/editor/editor.worker?worker', import.meta.url));
  }
};

window.monaco = monaco;