interface EnvironmentConfig {
    IAM_API_BASE_URL: string;
    B2C_CLIENT_ID: string;
    B2C_AUTHORITY: string;
    B2C_REDIRECT_URI: string;
    B2C_KNOWN_AUTHORITY: string;
    B2C_SCOPE: string;
    PIRAL_FEED_URL: string;
    DEBUG_PILET: string;
  }
  
  function validateEnvVar(key: keyof EnvironmentConfig): string {
    let value: string;
    switch (key) {
      case 'IAM_API_BASE_URL':
        value = process.env.REACT_APP_IAM_API_BASE_URL;
        break;
      case 'B2C_CLIENT_ID':
        value = process.env.REACT_APP_B2C_CLIENT_ID;
        break;
      case 'B2C_AUTHORITY':
        value = process.env.REACT_APP_B2C_AUTHORITY;
        break;
      case 'B2C_REDIRECT_URI':
        value = process.env.REACT_APP_B2C_REDIRECT_URI;
        break;
      case 'B2C_KNOWN_AUTHORITY':
        value = process.env.REACT_APP_B2C_KNOWN_AUTHORITY;
        break;
      case 'B2C_SCOPE':
        value = process.env.REACT_APP_B2C_SCOPE;
        break;
      case 'PIRAL_FEED_URL':
        value = process.env.REACT_APP_PIRAL_FEED_URL;
        break;
      case 'DEBUG_PILET':
        value = process.env.REACT_APP_DEBUG_PILET;
        break;
    }

    if (!value) {
      throw new Error(`Environment variable REACT_APP_${key} is not set`);
    }
    return value;
  }
  
  export const env: EnvironmentConfig = {
    IAM_API_BASE_URL: validateEnvVar('IAM_API_BASE_URL'),
    B2C_CLIENT_ID: validateEnvVar('B2C_CLIENT_ID'),
    B2C_AUTHORITY: validateEnvVar('B2C_AUTHORITY'),
    B2C_REDIRECT_URI: validateEnvVar('B2C_REDIRECT_URI'),
    B2C_KNOWN_AUTHORITY: validateEnvVar('B2C_KNOWN_AUTHORITY'),
    B2C_SCOPE: validateEnvVar('B2C_SCOPE'),
    PIRAL_FEED_URL: validateEnvVar('PIRAL_FEED_URL'),
    DEBUG_PILET: validateEnvVar('DEBUG_PILET'),
  };